<template>
  <v-card class="py-5">
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <div class="text-h4 success--text mt-15">
            <img
              width="150"
              src="@/assets/images/check-success.png"
              alt="check"
            />
          </div>
          <div class="text-h4 success--text mt-5">การชำระเงินเสร็จสิ้น</div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn
        outlined
        class="px-5 mr-10"
        color="primary"
        elevation="0"
        @click="print()"
      >
        พิมพ์ใบเสร็จ <v-icon right>mdi-printer</v-icon>
      </v-btn>
      <slot></slot>
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
        ปิด
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item_id: {
      required: true,
      type: String,
      default: null
    }
  },
  methods: {
    print() {
      this.$router.push({
        name: `package-payment-detail`,
        params: { id: this.item_id },
        query: { print: true }
      });
    },
    close() {
      this.$router.push({ name: `package-payment` });
    }
  }
};
</script>
