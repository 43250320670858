<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-card elevation="8" class="mx-auto my-12 popup-background">
        <v-card-text class="py-5 px-10">
          <div class="text-h3 primary--text text-center mb-10">
            ชำระเงิน Manual
          </div>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class=" text-left">
              <div>ราคารวม</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ data.total_price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ส่วนลดรวม</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ data.total_discount | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ราคาหลังหักส่วนลด</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>
                {{(data.total_price - data.total_discount) | numeral('0,0.00')}}
              </span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ภาษีมูลค่าเพิ่ม 7%</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ data.vat_price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6">
            <v-col cols="4" class=" text-left">
              <div align="left" class="mb-3">หัก ณ ที่จ่าย 3%</div>
            </v-col>
            <v-col cols="2" class=" text-right">
              <span>{{ data.withholding | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-row justify="center" class="text-h6 red--text">
            <v-col cols="4" class=" text-left">
              <div class="mb-3">จำนวนเงินรวมทั้งสิ้น</div>
            </v-col>
            <v-col cols="2" class=" text-right">
              <span>{{ data.net_price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="12 mb-5" align="center">
              <v-btn
                class="mr-2 mb-5"
                :color="form.payment_type == 1 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 1"
              >
                <v-icon left>mdi-qrcode-scan</v-icon>โอนผ่าน QR CODE
              </v-btn>

              <v-btn
                class="mr-2 mb-5"
                :color="form.payment_type == 2 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 2"
              >
                <v-icon left>mdi-credit-card</v-icon>ชำระผ่านบัตรเครดิต
              </v-btn>

              <v-btn
                class="mr-2 mb-5"
                :color="form.payment_type == 3 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 3"
              >
                โอนผ่านเลขบัญชี
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn outlined class="px-5 mr-10" color="red" @click="confirm()"
          >ยืนยันการชำระเงิน
          </v-btn>
          <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-dialog persistent scrollable max-width="600" v-model="dialogSuccess">
      <Success :item_id="bill_id" @onClose="dialogSuccess = false"></Success>
    </v-dialog>
  </v-row>
</template>

<script>
import PrintDocument from '@/components/PrintDocument/printDocument.vue';
import Success from '@/views/pages/package/components/Success.vue';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import * as moment from 'moment';
import { required, numeric, minValue } from 'vuelidate/lib/validators';

export default {
  components: {
    Success,
    PrintDocument,
    DatePicker,
    TimePicker
  },

  validations: {
    price: {
      getMoney: { required, numeric }
    }
  },

  data: () => ({
    dialogSuccess: false,
    isActivePay: true,
    form: {
      bill_id: '',
      net_price: 0,
      total_price: 0,
      total_discount: 0,
      withholding: 0,
      payment_type: 0
    },
    data: {
      customer_name: '',
      car_plate: '',
      car_province: ''
    },
    price: {
      period: 1, //งวด
      auto: true,
      bank: 1,
      getMoney: 0
    },
    banks: [],
    errors: {}
  }),

  async created() {
    this.form.bill_id = this.$route.params.id;
    this.bill_id = this.$route.params.id;
    await this.getData();
  },
  methods: {
    close() {
      this.$router.push({name: 'package-payment', params: { _id: this.bill_id }});
    },
    async confirm() {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {noTimestamp: true})
            };
            await this.$axios.post(`${this.$baseUrl}/backend/bill/pay_bill`, body)
              .then(res => {
                this.bill_id = res.result;
                this.dialogSuccess = true;
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async getData() {
      let body = {
        token: this.$jwt.sign({bill_id: this.$route.params.id}, this.$privateKey, {noTimestamp: true})
      };
      await this.$axios.post(`${this.$baseUrl}/backend/bill/get_by_id`, body)
        .then(async res => {
          this.data = { ...res.result };
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>
